import React from "react";
import './LandingPage.css'
import { Layout } from "antd";
const {Header, Content} = Layout;

function LandingPage() {

    return (
        <Layout>
            <Header className="header">
                <span><span className="h1">TimerMofa </span><sub>alpha</sub></span>

            </Header>
            <Content className="container">
            </Content>
        </Layout>
    )
}

export default LandingPage