import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import GroupTimerOverview from "./components/GroupTimerOverview";
import './App.css'
import LandingPage from "./components/LandingPage";

const App = () => {

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/groups/:groupId">
                        <GroupTimerOverview/>
                    </Route>
                    <Route exact path="/">
                        <LandingPage/>
                    </Route>
                </Switch>
            </Router>
        </div>

    )
};

export default App;