import React, {useEffect, useRef, useState} from 'react';

import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {Avatar, Card, Layout, Skeleton} from 'antd';
import {CaretRightOutlined, PauseOutlined, RollbackOutlined, UserOutlined} from '@ant-design/icons';
import './GroupTimerOverview.css'

const {Header, Content} = Layout;
const {Meta} = Card;

function GroupTimerOverview() {
    const [isConnected, setIsConnected] = useState(false);
    const [websocket, setWebsocket] = useState<WebSocket>();
    const {groupId} = useParams();
    const [userId, setUserId] = useState<String>();
    const [timers, setTimers] = useState<UserTimer[]>([]);
    const [reconnectInterval, setReconnectInterval] = useState<NodeJS.Timeout | null>(null);
    const [pingInterval, setPingInterval] = useState<NodeJS.Timeout | null>(null);

    const timersRef = useRef<UserTimer[]>();
    const websocketRef = useRef<WebSocket>();
    timersRef.current = timers;
    websocketRef.current = websocket;

    type Timer = {
        id: string
        time: number
        userId: number
    }

    type User = {
        id: string
        alias: string
        emailAddress: string
        firstName: string
        lastName: string
    }

    type UserTimer = {
        timer: Timer
        user: User
    }

    type Group = {
        id: string
        timer: UserTimer[]
    }

    useEffect(() => {
        const generatedUserId = uuidv4();
        setUserId(generatedUserId);
        connectWebsocket(generatedUserId);

        loadGroup();

        // Component will unmount
        return () => {
            if (reconnectInterval) {
                console.log("clearing reconnect loop");
                clearInterval(reconnectInterval);
                setReconnectInterval(null);
            }
            if (pingInterval) {
                console.log("clearing ping loop");
                clearInterval(pingInterval);
                setPingInterval(null);
            }
        };

    }, []);

    const loadGroup = async () => {
        const groupResult = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}`);
        const groupData: Group = groupResult.data;
        setTimers(groupData.timer.map(timer => timer));
    };

    const connectWebsocket = (generatedUserId: String) => {
        const webSocket = new WebSocket(`${process.env.REACT_APP_WS_API_URL}/websocket/group/${groupId}/user/${generatedUserId}`);
        setWebsocket(webSocket);

        webSocket.onopen = () => {
                console.info('Socket connected');
                setIsConnected(true);

                if(timersRef.current) {
                    loadGroup();
                }

                setPingInterval(setInterval(() => {
                    if (websocketRef.current) {
                        const message = {
                            timerId: null,
                            messageType: 'ping'
                        };

                        try {
                            websocketRef.current?.send(JSON.stringify(message))
                        } catch (e) {}
                    }
                }, 2000));
            };

        webSocket.onclose = () => {
                console.info('Socket disconnected');
                setIsConnected(false);
                setTimeout(function () {
                    console.log("trying to reconnect to socket");
                    connectWebsocket(userId ? userId : uuidv4())
                }, 1000);
            };

        webSocket.onerror = () => {
                console.error("Socket error");
            };

        webSocket.onmessage = (event => {
                const rawMessage = JSON.parse(event.data);
                if (rawMessage.hasOwnProperty("id")) {
                    const updatedTimer: Timer = rawMessage;
                    updateTimer(updatedTimer);
                    return
                }
            });

    };

    function updateTimer(updatedTimer: Timer) {
        if (timersRef.current) {
            const updatedTimers = timersRef.current.map(timer => {
                if (timer.timer.id === updatedTimer.id) {
                    const timerToMutate = timer;
                    timerToMutate.timer.time = updatedTimer.time;
                    return timerToMutate
                }
                return timer
            });
            setTimers(updatedTimers);
        }
    }

    const formatToReadableTime = (timeInSeconds: number) => {
        const isNegative = Math.sign(timeInSeconds) === -1;
        const absoluteTimeInSeconds = Math.abs(timeInSeconds);
        let hours: number | string = Math.floor(absoluteTimeInSeconds / 3600);
        let minutes: number | string = Math.floor((absoluteTimeInSeconds - hours * 3600) / 60);
        let seconds: number | string = Math.floor(absoluteTimeInSeconds - hours * 3600 - minutes * 60);

        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${isNegative ? '-' : ''}${hours}:${minutes}:${seconds}`;
    };

    function handleStartTimer(timerId: string) {
        const message = {
            timerId,
            messageType: 'startTimer'
        };

        if(isConnected) {
            console.log("Sending startTimer Message");
            websocket?.send(JSON.stringify(message))
        } else {
            console.error("Cant send startTimer Message. Not connected");
        }

    }

    function handlePauseTimer(timerId: string) {
        const message = {
            timerId,
            messageType: 'pauseTimer'
        };

        if(isConnected) {
            console.log("Sending pauseTimer Message");
            websocket?.send(JSON.stringify(message))
        } else {
            console.error("Cant send pauseTimer Message. Not connected");
        }
    }

    function handleResetTimer(timerId: string) {
        const message = {
            timerId,
            messageType: 'resetTimer'
        };

        if(isConnected) {
            console.log("Sending resetTimer Message");
            websocket?.send(JSON.stringify(message))
        } else {
            console.error("Cant send resetTimer Message. Not connected");
        }
    }

    return (
        <div className="Container">
            <Layout>
                <Header className="header"><span className="h1">
                    {isConnected ? (
                        <span className="dotConnected"/>
                    ) : (
                        <span className="dotDisconnected"/>
                    )}
                    &nbsp;{groupId}</span>
                </Header>
                <Content className="content">
                    {timers ? (
                        timers.map(userTimer => (
                            <div className="card" key={userTimer.timer.id}>
                                <Card
                                    style={{width: 300, marginTop: 16}}
                                    actions={[
                                        <div onClick={() => {
                                            handleStartTimer(userTimer.timer.id)
                                        }}><CaretRightOutlined key="play"/></div>,
                                        <div onClick={() => {
                                            handlePauseTimer(userTimer.timer.id)
                                        }}><PauseOutlined key="pause"/></div>,
                                        <div onClick={() => {
                                            handleResetTimer(userTimer.timer.id)
                                        }}><RollbackOutlined key="reset"/></div>
                                    ]}
                                >
                                    <Skeleton loading={false} avatar active>
                                        <Meta
                                            avatar={
                                                <Avatar shape="square" size={64} icon={<UserOutlined/>}/>
                                            }
                                            title={userTimer.user.alias}
                                            description={formatToReadableTime(userTimer.timer.time)}
                                        />
                                    </Skeleton>
                                </Card>
                            </div>
                        ))

                    ) : (
                        <></>
                    )
                    }
                    <div className="bottomSpacer"></div>
                </Content>
            </Layout>
        </div>
    );
}

export default GroupTimerOverview;
